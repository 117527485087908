<template>
  <div>
    <div class="borderColor borderColorBackground mb-3">
      <div class="d-flex align-items-center flex-wrap m-2">
        <div class="my-2 mr-4">
          選擇日期 :&nbsp;
          <date-picker
            v-model="beginDate"
            placeholder="選擇日期"
            :type="dateType"
            valueType="format"
            :format="dateFormat"
            style="width: 150px"
            :disabled-date="disabledDateAfterToday"
          />
        </div>
        <div class="my-2 mr-4">
          起始時間 :&nbsp;
          <date-picker
            v-model="beginTime"
            placeholder="起始時間:"
            type="time"
            format="HH:mm"
            valueType="format"
            style="width: 130px"
          />
        </div>
        <div class="my-2 mr-4">
          結束時間 :&nbsp;
          <date-picker
            v-model="endTime"
            placeholder="結束時間:"
            type="time"
            format="HH:mm"
            valueType="format"
            style="width: 130px"
          />
        </div>
        <button
          type="button"
          class="datepickerButton"
          @click="multipleDataPush"
        >
          新增一列
        </button>
      </div>
      <div class="otherTableStyle bg-white">
        <table class="w-100 text-center">
          <tr>
            <th>序號</th>
            <th>
              裝置 /
              <select v-model="whichIrr" class="m-5px" @change="resetInv">
                <option value="">不篩選</option>
                <option :value="x" v-for="(x, idx) in dedupIrrMap()" :key="idx">
                  {{ `日照計-${x + 1}` }}
                </option>
              </select>
            </th>
            <th>曲線顏色</th>
            <th>功能</th>
          </tr>
          <tr v-for="(i, idx) in multipleDataArr" :key="idx">
            <td>{{ idx + 1 }}</td>
            <td>
              <select v-model="i.inv" class="m-5px" style="min-width: 100px">
                <!-- <option value="">INV</option> -->
                <option
                  :value="x"
                  v-for="(x, idx) in filterInvArr()"
                  :key="idx"
                >
                  {{ x }}
                </option>
              </select>
              <select v-model="i.mppt" class="m-5px">
                <option v-for="y in buildMpptOption(i.inv)" :key="y" :value="y">
                  {{ y }}
                </option>
              </select>
            </td>
            <td>
              <input type="color" v-model="i.color" />
            </td>
            <td>
              <button
                type="button"
                class="deleteButton m-5px"
                @click="multipleDataSplice(idx)"
              >
                刪除
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="bg-white text-center">
        <button type="button" class="confirmButton m-2" @click="confirmData">
          確認
        </button>
      </div>
    </div>

    <div class="borderColor borderColorBackground mb-4">
      <div class="d-flex align-items-center m-3">
        <b-form-checkbox-group
          v-model="contextDataClass"
          :options="contextArr"
          switches
        />
        <button
          type="button"
          class="confirmButton ml-auto"
          style="width: 120px"
          @click="downloadMpptDataCsv"
        >
          資料下載
        </button>
      </div>
      <highcharts ref="chart" :options="multipleChart" class="bg-white pt-3" />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { getMpptLenApi, getMpptDataApi } from '../api'
import { mapState } from 'vuex'
import downloadCsv from 'download-csv'
import { disabledDateAfterToday } from '@/utils/datetime'
export default {
  name: 'MpptAnalysis',
  computed: {
    ...mapState(['factoryInfo'])
  },
  data () {
    return {
      beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
      beginTime: DateTime.local().minus({ minutes: 1 }).toFormat('HH:mm'),
      endTime: DateTime.local().toFormat('HH:mm'),
      dateFormat: 'YYYY-MM-DD',
      dateType: 'date',
      dateTypeActive: 'hour',
      irrArr: [],
      invArr: [],
      mpptDataArr: [],
      mpptLenArr: [],
      whichIrr: '',
      contextDataClass: ['voltage', 'current', 'power', 'irr'],
      contextArr: [
        {
          value: 'voltage',
          text: '電壓 (V)'
        },
        {
          value: 'current',
          text: '電流 (A)'
        },
        {
          value: 'power',
          text: '功率 (kW)'
        },
        {
          value: 'irr',
          text: '日照量 (kWh/m²)'
        }
      ],
      multipleDataArr: [
        {
          inv: 'INV01',
          mppt: 'MPPT01',
          color: '#f6b73c'
        },
        {
          inv: 'INV01',
          mppt: 'MPPT01',
          color: '#1c1c1c'
        }
      ],
      multipleChart: {
        chart: {
          height: 350,
          backgroundColor: null,
          events: {
            load () {
              this.showLoading('尚未有資料，請選擇MPPT')
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
          floating: false
        },
        yAxis: [],
        tooltip: {
          shared: true
        },
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  y: 0
                }
              }
            }
          ]
        }
      },
      irrMap: []
    }
  },
  methods: {
    disabledDateAfterToday,
    multipleDataPush () {
      this.multipleDataArr.push({
        inv: 'INV01',
        mppt: 'MPPT01',
        color: '#00458F'
      })
    },
    multipleDataSplice (idx) {
      this.multipleDataArr.splice(idx, 1)
    },
    filterInvArr () {
      if (this.whichIrr === '') return this.invArr

      const irrMap = this.irrMap
        .map((y, yidx) => {
          if (y === this.whichIrr) return yidx
        })
        .filter((a) => a !== undefined)

      return this.invArr.filter((x, idx) => {
        return irrMap.includes(idx)
      })
    },
    dedupIrrMap () {
      return [...new Set(this.irrMap)]
    },
    resetInv () {
      this.multipleDataArr.forEach((x) => {
        x.inv = this.filterInvArr()[0]
      })
    },
    confirmData () {
      this.multipleDataArr.every((x) => Object.values(x).every((y) => y !== ''))
        ? this.getMpptData()
        : this.$swal('請正確選擇')
    },
    buildChart () {
      this.multipleChart.xAxis.categories = []
      this.multipleChart.yAxis = []
      this.multipleChart.series = []

      // xAxis.categories
      this.mpptDataArr.forEach((x) => {
        this.multipleChart.xAxis.categories.push(x.time)
      })

      // yAxis
      this.contextDataClass.forEach((selectedOption, idx) => {
        let yAxisText = this.contextArr.find(
          (option) => option.value === selectedOption
        ).text
        this.multipleChart.yAxis.push({
          title: {
            text: `${yAxisText}`
          },
          opposite: idx % 2 === 0
        })
      })

      // series obj 電壓 電流 功率
      this.multipleDataArr.forEach((data) => {
        let selectedOptionRemoveIrr = this.contextDataClass.filter(
          (item) => item !== 'irr'
        )
        selectedOptionRemoveIrr.forEach((selectedOption) => {
          let text = this.contextArr.find(
            (option) => option.value === selectedOption
          ).text

          let seriesObj = {
            type: 'line',
            color: data.color === '' ? '#1c1c1c' : data.color,
            name: `${data.inv}_${data.mppt}_${text}`,
            data: [],
            yAxis: this.multipleChart.yAxis.findIndex(
              (item) => item.title.text === text
            )
          }

          this.multipleChart.series.push(seriesObj)
        })
      })

      // series.data 電壓 電流 功率
      this.mpptDataArr.forEach((data) => {
        this.multipleChart.series.forEach((obj) => {
          let value = this.contextArr.find(
            (y) => y.text === obj.name.split('_')[2]
          ).value
          let inv = this.invArr.findIndex((y) => y === obj.name.split('_')[0])
          let mppt = this.buildMpptOption(obj.name.split('_')[0]).findIndex(
            (y) => y === obj.name.split('_')[1]
          )
          obj.data.push(data[value][inv][mppt])
        })
      })

      // 若有選擇 irr 日照量
      // 需與其他 電壓 電流 等項目 分開計算
      let selectIrr = this.contextDataClass.find(
        (selection) => selection === 'irr'
      )
      if (selectIrr) {
        let selectedIrrNo = this.whichIrr === '' ? 0 : this.whichIrr

        let irrData = this.mpptDataArr.map((item) => item.irr[selectedIrrNo])
        this.multipleChart.series.push({
          type: 'line',
          color: '#5B9F23',
          name: `IRR0${selectedIrrNo + 1}_日照量 (kWh/m²)`,
          data: irrData,
          yAxis: this.multipleChart.series.length / this.multipleDataArr.length
        })
      }
      this.hideHighChartLoading()
    },
    hideHighChartLoading () {
      this.$nextTick(function () {
        if (this.$refs && this.$refs.chart) {
          this.$refs.chart.chart.hideLoading()
          this.multipleChart.chart.events = {}
        }
      })
    },
    buildMpptOption (inv) {
      const idx = this.invArr.findIndex((x) => x === inv)
      return Array.from(
        { length: this.mpptLenArr[idx] },
        (v, i) => `MPPT${('0' + (i + 1)).slice(-2)}`
      )
    },
    downloadMpptDataCsv () {
      const downloadArr = []
      let fields = ['name']
      fields = fields.concat(this.multipleChart.xAxis.categories)
      this.multipleChart.series.forEach((x) => {
        downloadArr.push({ name: x.name })
      })

      const download = []
      this.multipleChart.series.forEach((_) => {
        download.push({ data: [] })
      })

      this.multipleChart.series.forEach((obj, idx) => {
        download[idx].data.push(obj.data)
      })

      this.multipleChart.xAxis.categories.forEach((x, xindex) => {
        downloadArr.forEach((y, yindex) => {
          y[`${x}`] = download[yindex].data[xindex]
        })
      })
      downloadCsv(downloadArr, fields, 'MPPT 分析')
    },
    async getMpptLen () {
      const mpptLen = await getMpptLenApi(
        this.$route.query.siteName
      )
      if (mpptLen?.data?.success) {
        this.mpptLenArr = mpptLen.data.data
      }
    },
    async getMpptData () {
      const mpptData = await getMpptDataApi(
        this.$route.query.siteName,
        this.beginTime,
        this.endTime,
        this.beginDate,
        this.$route.query.fid
      )
      if (mpptData?.data?.success) {
        this.mpptDataArr = mpptData.data.data
        this.buildChart()
      }
    }
  },
  watch: {
    contextDataClass: function (oldValue, newValue) {
      if (oldValue !== newValue && this.mpptDataArr.length !== 0) {
        this.buildChart()
      }
    }
  },
  async mounted () {
    await this.getMpptLen()
    this.invArr = Array.from(
      { length: this.$route.query.invLen },
      (v, i) => `INV${('0' + (i + 1)).slice(-2)}`
    )
    const factoryInfo = this.factoryInfo.find(
      (x) => x.factory === this.$route.query.siteName
    )
    this.irrMap = factoryInfo.irrMap ? factoryInfo.irrMap : []
  }
}
</script>
